import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import Gallery from "react-photo-gallery"
import Img from "gatsby-image"
import Layout from "../components/layout"

const GalleryTemplate = ({ pageContext }) => {
  const { images } = pageContext

  const photos = images.map(({ fluid, width, height, originalId }) => ({
    id: originalId,
    fluid,
    width,
    height,
  }))

  const imageRenderer = useCallback(
    ({ photo }) => (
      <Img
        style={{ width: photo.width, height: photo.height }}
        key={photo.id}
        css={css`
          img {
            padding: 4px;
          }
        `}
        fluid={photo.fluid}
      />
    ),
    []
  )
  return (
    <Layout title="">
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Gallery
          photos={photos}
          targetRowHeight={450}
          renderImage={imageRenderer}
        />
      </div>
    </Layout>
  )
}

GalleryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
}

export default GalleryTemplate
