import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import Header from "./header"
import LeftNav from "./leftnav"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div
      css={css`
        min-height: 100vh;
        padding: 20px 20px;
      `}
    >
      <Header siteTitle="Sheryl Oppenheim" />
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            flex: 30%;
            z-index: 2;
          `}
        >
          <LeftNav />
        </div>
        <div
          css={css`
            margin: 0px auto;
            margin-left: -20%;
            flex: 70%;
          `}
        >
          <main>{children}</main>
          <footer />
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
