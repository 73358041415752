import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Header = ({ siteTitle }) => (
  <header
    css={css`
      margin-bottom: 1.45rem;
    `}
  >
    <div
      css={css`
        max-width: 960px;
        text-align: center;
        margin: 0px auto;
      `}
    >
      <h1>
        <Link
          css={css`
            text-decoration: none;
            color: inherit;
            font-size: 3.2rem;
          `}
          to="/"
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
