import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const LeftNav = () => (
  <div
    css={css`
      color: rgb(238, 217, 47);
      display: flex;
      flex-direction: column;
      font-size: 2rem;
      font-family: Karla;
      padding: 1rem;

      a {
        text-decoration: none;
        line-height: 1.1em;
      }
      > div {
        display: flex;
        flex-direction: column;
      }
    `}
  >
    <div>
      <Link to="/">Paintings</Link>
      <Link to="/marbling">Marbling</Link>
      <Link to="/suminagashi">Suminagashi</Link>
      <Link to="/about-news">News/Contact</Link>
    </div>
    <div
      css={css`
        margin-top: 2rem;
      `}
    >
      <div
        css={css`
          color: #000;
        `}
      >
        Books:
      </div>
      <Link to="/sample-book">Sample Book</Link>
      <Link to="/black-and-white-studies">Black & White Studies</Link>
      <Link to="/black-light">Black Light</Link>
      <Link to="/black-hours">Black Hours</Link>
      <Link to="/alba-amicorum">Alba Amicorum</Link>
      <Link to="/alba-amicorum-zine">Alba Zine</Link>
      <Link to="/about-news">About + News</Link>
    </div>
  </div>
)

LeftNav.propTypes = {}

export default LeftNav
